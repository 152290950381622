export default [
  {
    label: "Northland",
    value: "Northland"
  },
  {
    label: "Auckland",
    value: "Auckland"
  },
  {
    label: "Waikato",
    value: "Waikato"
  },
  {
    label: "Bay of Plenty",
    value: "Bay of Plenty"
  },
  {
    label: "Gisborne",
    value: "Gisborne"
  },
  {
    label: "Hawke’s Bay",
    value: "Hawke’s Bay"
  },
  {
    label: "Taranaki",
    value: "Taranaki"
  },
  {
    label: "Manawatu-Wanganui",
    value: "Manawatu-Wanganui"
  },
  {
    label: "Wellington",
    value: "Wellington"
  },
  {
    label: "Nelson-Marlborough",
    value: "Nelson-Marlborough"
  },
  {
    label: "West Coast",
    value: "West Coast"
  },
  {
    label: "Canterbury",
    value: "Canterbury"
  },
  {
    label: "Otago",
    value: "Otago"
  },
  {
    label: "Southland",
    value: "Southland"
  }
];
