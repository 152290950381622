export default [
  {
    label: "Agriculture, Forestry and Fishing",
    value: "Agriculture, Forestry and Fishing"
  },
  {
    label: "Mining",
    value: "Mining"
  },
  {
    label: "Manufacturing",
    value: "Manufacturing"
  },
  {
    label: "Electricity, Gas Water and Waste Services",
    value: "Electricity, Gas Water and Waste Services"
  },
  {
    label: "Construction",
    value: "Construction"
  },
  {
    label: "Wholesale Trade",
    value: "Wholesale Trade"
  },
  {
    label: "Retail Trade",
    value: "Retail Trade"
  },
  {
    label: "Accommodation and Food Services",
    value: "Accommodation and Food Services"
  },
  {
    label: "Transport, Postal and Warehousing",
    value: "Transport, Postal and Warehousing"
  },
  {
    label: "Information Media and Telecommunications",
    value: "Information Media and Telecommunications"
  },
  {
    label: "Financial and Insurance Services",
    value: "Financial and Insurance Services"
  },
  {
    label: "Rental, Hiring and Real Estate Services",
    value: "Rental, Hiring and Real Estate Services"
  },
  {
    label: "Professional, scientific and Technical Services",
    value: "Professional, scientific and Technical Services"
  },
  {
    label: "Administrative and Support Services",
    value: "Administrative and Support Services"
  },
  {
    label: "Public Administration and Safety",
    value: "Public Administration and Safety"
  },
  {
    label: "Education and Training",
    value: "Education and Training"
  },
  {
    label: "Health Care and Social Assistance",
    value: "Health Care and Social Assistance"
  },
  {
    label: "Arts and Recreation Services",
    value: "Arts and Recreation Services"
  },
  {
    label: "Other Services",
    value: "Other Services"
  }
];
