<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700"
      >{{ label }} <span class="text-brand">*</span></label
    >
    <select
      required
      :id="id"
      :name="id"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand focus:border-brand sm:text-sm rounded-md"
      @change="$emit('input', $event.target.value)"
    >
      <option selected disabled value="">Please select an option</option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{
        option.label
      }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      value: ""
    },
    options: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style></style>
