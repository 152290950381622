<template>
  <div class="py-12 px-6 w-full max-w-lg mx-auto">
    <img class="w-56 block mx-auto mb-10" src="../assets/logo.png" alt="Employee Confidence" />
    <transition name="fade" mode="out-in">
      <div key="loading" v-if="state === 'loading'">
        <Loader></Loader>
      </div>
      <div key="loading" v-else-if="state === 'error'">
        <p class="text-center text-red-500 italic">{{ error }}</p>
      </div>
      <div key="complete" v-else-if="state === 'complete'">
        <p class="text-center text-gray-700 italic">
          Thank you. For more information on Employee Confidence, visit
          <a
            class="text-brand hover:text-brand-dark focus:text-brand-dark"
            target="_blank"
            href="https://employeeconfidence.nz/"
            >www.employeeconfidence.co.nz</a
          >
        </p>
      </div>
      <div key="incomplete" v-else>
        <div class="font-semibold text-lg text-center mb-2">
          Overall, I have confidence in the current and future success of the organisation I work
          for
        </div>
        <form @submit.prevent="submit()">
          <Slider class="mb-8" v-model="form[1]" />
          <Select
            v-model="form[3]"
            class="mb-6"
            label="Region"
            :id="`input_region`"
            :options="regions"
          ></Select>
          <Select
            v-model="form[2]"
            class="mb-6"
            label="Employment Industry"
            :id="`input_industry`"
            :options="industries"
          ></Select>
          <Radio
            v-model="form[4]"
            class="mb-6"
            label="Gender"
            :id="`input_gender`"
            :options="genders"
          ></Radio>
          <BaseInput
            v-if="form[4] === 'Prefer to self define'"
            v-model="other_gender"
            class="mb-6"
            label="Other Gender"
            :id="`input_other_gender`"
          ></BaseInput>
          <div class="flex justify-center">
            <button
              type="submit"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand transition duration-75"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import regions from "../regions";
import industries from "../industries";
import Slider from "../components/Slider.vue";
import Select from "../components/Select.vue";
import Radio from "../components/Radio.vue";
import BaseInput from "../components/Input.vue";
import Loader from "../components/Loader.vue";

export default {
  name: "Home",
  components: { Slider, Select, Radio, BaseInput, Loader },
  data() {
    return {
      surveyId: null,
      baseUrl: "https://employeeconfidence.nz/api",
      regions,
      industries,
      state: "loading",
      error: "",
      genders: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Prefer to Self Define", value: "Prefer to self define" }
      ],
      other_gender: "",
      form: {
        1: 5,
        2: "",
        3: "",
        4: ""
      }
    };
  },
  created() {
    this.surveyId = this.$route.params.id;
    this.getSurvey();
  },
  methods: {
    getSurvey() {
      axios
        .get(`${this.baseUrl}/questionnaires/${this.surveyId}`, {
          withCredentials: true
        })
        .then(response => {
          const doneByCurrentParticipant = response.data.data.done_by_current_participant;

          if (doneByCurrentParticipant) {
            this.state = "complete";
          } else {
            this.state = "incomplete";
          }
        })
        .catch(err => {
          this.error = err.response.data.error.message;
          this.state = "error";
        });
    },
    submit() {
      this.state = "loading";

      axios
        .post(`${this.baseUrl}/questionnaires/${this.surveyId}`, this.formattedPayload, {
          withCredentials: true
        })
        .then(response => {
          this.state = "complete";
          console.log(response);
        })
        .catch(err => {
          this.state = "incomplete";
          console.log(err);
        });
    }
  },
  computed: {
    formattedPayload() {
      const payload = [];
      const ansObj = { ...this.form };

      if (ansObj[4] === "Prefer to self define" && this.other_gender) {
        ansObj[4] = this.other_gender;
      }

      Object.entries(ansObj).forEach(([key, value]) => {
        payload.push({
          question_id: key,
          answer: value
        });
      });
      return payload;
    }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
