<template>
  <div>
    <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
    <div class="flex items-center mb-1" v-for="option in options" :key="option.value">
      <input
        type="radio"
        :id="`radio_option_${option.value}`"
        :value="option.value"
        :checked="value === option.value"
        @input="$emit('input', $event.target.value)"
        class="mr-2 focus:outline-none focus:ring-brand focus:border-brand"
        :name="label"
        required
      />
      <label class="text-sm" :for="`radio_option_${option.value}`">{{ option.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      value: ""
    },
    options: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style></style>
