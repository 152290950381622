<template>
  <div>
    <div class="w-full flex text-gray-700 font-medium text-sm pt-6">
      <span class="w-1/3">Disagree</span>
      <span class="w-1/3 text-center">Neutral</span>
      <span class="w-1/3 text-right">Agree</span>
    </div>
    <div class="px-3">
      <div class="employee-confidence-slider">
        <vue-slider
          v-bind="options"
          ref="slider"
          :value="value"
          @change="handleInput"
          :piecewise="true"
          :piecewiseStyle="{ backgroundColor: '#ccc', width: '4px', height: '4px' }"
        ></vue-slider>
      </div>
    </div>
    <div class="w-full flex justify-between text-gray-500 text-sm px-2">
      <span>0</span>
      <span>10</span>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  components: { VueSlider },
  props: {
    value: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      options: {
        eventType: "auto",
        width: "auto",
        height: 15,
        dotSize: 30,
        tooltip: "always",
        tooltipPlacement: "bottom",
        min: 0,
        max: 10,
        disabled: false,
        marks: true,
        hideLabel: true,
        sliderStyle: {
          backgroundColor: this.brandColor,
          boxShadow: "none"
        }
      }
    };
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style lang="scss">
.employee-confidence-slider {
  .vue-slider-rail {
    @apply bg-gray-100;
  }
  .vue-slider-process {
    @apply bg-transparent;
  }
  .vue-slider-dot-handle {
    @apply bg-brand border-none;
    &:hover {
      @apply bg-brand-dark;
    }
  }
  .vue-slider-marks {
    .vue-slider-mark {
      width: 5px !important;
      height: 5px !important;
      &:first-child,
      &:last-child {
        display: none;
      }
      .vue-slider-mark-step {
        @apply bg-gray-300 border-none shadow-none;
      }
    }
  }
  .vue-slider-dot-tooltip {
    @apply top-0 bottom-0 left-0 right-0;
    transform: none !important;
    .vue-slider-dot-tooltip-inner {
      @apply bg-transparent shadow-none text-white h-full flex items-center justify-center;
      &::after {
        display: none;
      }
    }
  }
  .vue-slider:hover {
    .vue-slider-rail {
      @apply bg-gray-200;
    }
    .vue-slider-process {
      @apply bg-transparent;
    }
  }
}
</style>
